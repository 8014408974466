import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const config = useRuntimeConfig();

    if (!config.public.SENTRY_DSN_PUBLIC) {
        return;
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: config.public.SENTRY_DSN_PUBLIC,
        environment: 'development',
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [config.public.basePath],

        replaysSessionSampleRate: 0.5, // Change in prod
        replaysOnErrorSampleRate: 0.5, // Change in prod if necessary
    });
});
