<script>
export default {
    props: ['error'],
}
</script>
<template>
    <div class="w-full h-screen">
        <NuxtLayout v-if="error.statusCode === 404" name="404">
            <div class="flex flex-col items-center w-full gap-8 pt-24 uppercase font-semibold px-4 sm:px-0">
                <div class="text-9xl font-extrabold artist-gradient">404</div>
                <div class="text-4xl">Eh beh</div>
                <div class="text-4xl text-center">
                    encore un sacré <del>débile</del> <span class="artist-gradient">génie</span>
                </div>
                <div class="flex flex-col gap-2 items-center">
                    <UButton to="/"
                        class="bg-wankil_blue-500 dark:bg-wankil_blue-100 w-fit hover:bg-wankil_blue-100 dark:hover:bg-wankil_blue-500 text-white hover:text-white"
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                        Revenir en lieu sûr pour sauver tes données
                    </UButton>
                    <span class="text-sm italic normal-case font-normal">C'est drôle ça donne pas tellement envie de
                        cliquer en
                        fait...</span>
                </div>
                <NuxtImg class="w-40 sm:w-80 xl:w-96 absolute bottom-0 sm:left-0 xl:left-20 left-0 2xl:left-40"
                    src="/images/laink_back.png" />
                <NuxtImg class="w-40 sm:w-80 xl:w-96 absolute bottom-0 sm:right-0 xl:right-20 right-0 2xl:right-40"
                    src="/images/terra_back.png" />
            </div>
        </NuxtLayout>
        <NuxtLayout v-else name="500">
            <div class="flex flex-col items-center w-full gap-8 pt-24 uppercase font-semibold px-4 sm:px-0">
                <div class="text-9xl font-extrabold artist-gradient">500</div>
                <div class="text-4xl"><del>Le dev</del> <span class="artist-gradient">Tu</span></div>
                <div class="text-4xl text-center">
                    as fait n'importe quoi
                </div>
                <div class="flex flex-col gap-2 items-center">
                    <UButton to="/"
                        class="bg-wankil_blue-500 dark:bg-wankil_blue-100 w-fit hover:bg-wankil_blue-100 dark:hover:bg-wankil_blue-500 text-white hover:text-white"
                        inactive-class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
                        Revenir en lieu sûr pour sauver tes données
                    </UButton>
                    <span class="text-sm italic normal-case font-normal">C'est drôle ça donne pas tellement envie de
                        cliquer en
                        fait...</span>
                </div>
                <NuxtImg class="w-32 sm:w-52 xl:w-96 absolute bottom-0 sm:left-8 xl:left-20 left-0 2xl:left-40"
                    src="/images/laink_face.png" />
                <NuxtImg class="w-32 sm:w-52 xl:w-96 absolute bottom-0 sm:right-8 xl:right-20 right-0 2xl:right-40"
                    src="/images/terra_face.png" />
            </div>
        </NuxtLayout>
    </div>
</template>
