import { default as _91id_93QP2nj8Ngh5Meta } from "/app/pages/cards/[id].vue?macro=true";
import { default as indexhEnu7n5u7IMeta } from "/app/pages/cards/index.vue?macro=true";
import { default as noworrieslhYvIvxEarMeta } from "/app/pages/donation/noworries.vue?macro=true";
import { default as thanksSIoi4fMmlkMeta } from "/app/pages/donation/thanks.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "cards-id",
    path: "/cards/:id()",
    component: () => import("/app/pages/cards/[id].vue").then(m => m.default || m)
  },
  {
    name: "cards",
    path: "/cards",
    component: () => import("/app/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "donation-noworries",
    path: "/donation/noworries",
    component: () => import("/app/pages/donation/noworries.vue").then(m => m.default || m)
  },
  {
    name: "donation-thanks",
    path: "/donation/thanks",
    component: () => import("/app/pages/donation/thanks.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]