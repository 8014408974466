export default defineNuxtPlugin({
  name: 'add-flex-to-nuxt',
  enforce: 'pre',
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    'app:mounted'() {
      const nuxtDiv = document.getElementById('__nuxt');
      if (nuxtDiv) {
        nuxtDiv.classList.add('flex');
      }
    },
  },
});
