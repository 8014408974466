import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import addFlexToNuxt_D4qOrcHB6a from "/app/plugins/addFlexToNuxt.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_KZ0f4ARq4r from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import slideovers_LDumGYo2KH from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_cBh4f50wXK from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  addFlexToNuxt_D4qOrcHB6a,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_KZ0f4ARq4r,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_cBh4f50wXK
]